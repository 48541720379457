import * as PIXI from "pixi.js";
import { Audio, AudioHelper } from "../../../../common/helpers/audio.helper";
import {
    GameBoardDefinition,
    GameDefinition,
    GameReel,
    NumberRange
} from "../../game.properties";
import { GameBoardLayerProps } from "./game-board-layer.props";
import { GameConfiguration } from "../../game-configuration.interface";
import { GameImageLayer } from "../image/game-image.layer";
import { GamePaylineLayer } from "../payline/game-payline.layer";
import { GameReelLayer } from "../reel/game-reel.layer";
import { PixiJSRenderingLayer } from "../../../../common/utilities/pixijs-rendering-layer";
import { SlotsPickActionDto } from "../../../../services/slots/dtos/slots-pick-action.dto";
import { SlotsSpinActionDto } from "../../../../services/slots/dtos/slots-spin-action.dto";
import gsap from "gsap";

type GameAnticipationsIndex<TReels extends number> = `${GameReel<TReels>}Anticipation`;
type GamePickemIndex<TPickSlots extends number> = `${NumberRange<24>}Pickem`; // TODO: FIX TYPE

const AnticipationTransitionDelay = 0.1;
const PickemTransitionDelay = 0.1;

export class GameBoardLayer<TSymbols extends string = string, TReels extends number = 1, TPickSlots extends number = 24> extends PixiJSRenderingLayer<
GameConfiguration,
{
    background: GameImageLayer;
    payLine: GamePaylineLayer;
    frame: GameImageLayer;
} & {
    [K in GameReel<TReels>]: GameReelLayer<TSymbols, TReels>
} & {
    [K in GameAnticipationsIndex<TReels>]: GameImageLayer
} & {
    [K in GamePickemIndex<TPickSlots>]?: GameImageLayer;
},
"",
GameBoardLayerProps<TSymbols>,
PIXI.Container
> {
    private mask = new PIXI.Graphics();
    private reels: Array<GameReel<TReels>>;
    private reelAnticipationSound: Audio[] = [];
    private pickBackgroundSound: Audio[] = [];
    private reelSpinningSound: Audio[] = [];
    private winSound: Audio[] = [];
    private bigWinSound: Audio[] = [];
    private pickStartSound: Audio[] = [];
    private pickEndSound: Audio[] = [];
    private pickedSound: Audio[] = [];
    private isSkipping = false;
    // private logoAnimationTimer: ReturnType<typeof setInterval> | undefined;
    private pickEmPromise: Promise<void> | undefined;

    public constructor(
        configuration: GameConfiguration,
        app: PIXI.Application,
        public readonly game: GameDefinition,
        public readonly board: GameBoardDefinition
    ) {
        super(
            configuration,
            app,
            new PIXI.Container(),
            {
                state: "idle",
                paylines: [],
                result: null,
                scatters: null,
                wilds: null,
                anticipation_max_scatter: null,
                anticipation_min_scatter: null,
                picks: null,
                scores: null,
            },
            {
                background: new GameImageLayer(
                    configuration,
                    app,
                    board.frame.background
                ),
                // add reels
                ...(
                    Object.fromEntries(
                        [
                            ...(
                                Object
                                    .keys(board.reels.symbols)
                                    .reverse()
                                    .map(
                                        (k, i) => (
                                            [
                                                k as GameReel<TReels>,
                                                new GameReelLayer(
                                                    configuration,
                                                    app,
                                                    Object.keys(board.reels.symbols).length - (i + 1),
                                                    game,
                                                    board.reels
                                                ),
                                            ]
                                        )
                                    )
                            ),
                        ]
                    )
                ),
                payLine: new GamePaylineLayer(
                    configuration,
                    app,
                    board
                ),
                frame: new GameImageLayer(
                    configuration,
                    app,
                    board.frame.foreground
                ),
                // add anticipations
                ...(
                    Object.fromEntries(
                        [
                            ...(
                                Object
                                    .keys(board.reels.symbols)
                                    .reverse()
                                    .map(
                                        (k) => (
                                            [
                                                `${k}Anticipation` as GameAnticipationsIndex<TReels>,
                                                new GameImageLayer(
                                                    configuration,
                                                    app,
                                                    board.reels.anticipation
                                                ),
                                            ]
                                        )
                                    )
                            ),
                        ]
                    )
                ),
                // add pickems
                ...(
                    Object.fromEntries(
                        [
                            ...(
                                ([ ...new Array<unknown>(board.reels.windowsSize * Object.keys(board.reels.symbols).length) ])
                                    .map(
                                        (_, i) => (
                                            [
                                                `${i}Pickem` as GamePickemIndex<TPickSlots>,
                                                new GameImageLayer(
                                                    configuration,
                                                    app,
                                                    board.reels.pickem
                                                ),
                                            ]
                                        )
                                    )
                            ),
                        ]
                    )
                ),
            }
        );

        this.reels = [ ...Object.keys(this.board.reels.symbols) ] as Array<GameReel<TReels>>;

        for (const pick of this.getPickems()) {
            pick.container.visible = false;
        }

        for (const anticipation of this.getAnticipations()) {
            anticipation.container.visible = false;
        }

        this.reelAnticipationSound = AudioHelper.getAudioList(board.reels.anticipation.sound);
        this.pickBackgroundSound = AudioHelper.getAudioList(board.reels.pickem.sound);
        this.reelSpinningSound = AudioHelper.getAudioList(game.sounds.spin);
        this.winSound = AudioHelper.getAudioList(game.sounds.win);
        this.bigWinSound = AudioHelper.getAudioList(game.sounds["big-win"]);
        this.pickStartSound = AudioHelper.getAudioList(game.sounds["pick-start"]);
        this.pickEndSound = AudioHelper.getAudioList(game.sounds["pick-end"]);
        this.pickedSound = AudioHelper.getAudioList(game.sounds.picked);
    }

    public destroy(): void {
        super.destroy();

        // if (this.logoAnimationTimer) {
        //     clearInterval(this.logoAnimationTimer);
        // }
    }

    public resize(width: number, height: number): void {
        const layout = this.calculateLayout(
            width,
            height,
            {
                fit: "contain",
                maxScale: 1,
                ...this.board.frame,
            }
        );

        const factor = layout.scale;
        const boardWidth = layout.width;
        const boardHeight = layout.height;

        super.resize(boardWidth, boardHeight);
        this.move(layout.x, layout.y);

        const isLandscape = width > height;
        const marginX = (isLandscape ? this.board.frame.marginLandscapeX : this.board.frame.marginPortraitX) ?? 0;
        const marginY = (isLandscape ? this.board.frame.marginLandscapeY : this.board.frame.marginPortraitY) ?? 0;

        this.shapes.background.resize(boardWidth, boardHeight);
        this.shapes.background.move(marginX * factor, marginY * factor);

        this.shapes.frame.resize(boardWidth, boardHeight);
        this.shapes.frame.move(marginX * factor, marginY * factor);

        const repeatGap = (this.board.reels.size + this.board.reels.gap) * factor;
        let sectionOffsetX = (marginX + this.board.reels.outer.offsetX) * factor;
        let sectionOffsetY = (marginY + this.board.reels.outer.offsetY) * factor;
        let sectionWidth = (this.board.reels.anticipation.width ?? 0) * factor;
        let sectionHeight = (this.board.reels.anticipation.height ?? 0) * factor;

        const anticipations = this.getAnticipations();
        for (let i = 0; i < anticipations.length; i++) {
            anticipations[i].resize(sectionWidth, sectionHeight);
            anticipations[i].move((repeatGap * i) + sectionOffsetX, sectionOffsetY);
        }

        sectionWidth = (this.board.reels.pickem.width ?? 0) * factor;
        sectionHeight = this.board.reels.size * factor;
        const pickems = this.getPickems();
        for (let i = 0; i < pickems.length; i++) {
            const row = i % this.board.reels.windowsSize;
            const column = Math.trunc(i / this.board.reels.windowsSize);

            pickems[i].container.hitArea = new PIXI.Rectangle(
                0,
                0,
                sectionHeight,
                sectionHeight
            );
            pickems[i].resize(sectionWidth, sectionWidth);
            pickems[i].move((repeatGap * column) + sectionOffsetX, sectionOffsetY + (row * sectionHeight));
        }

        sectionOffsetX += this.board.reels.inner.offsetX * factor;
        sectionOffsetY += this.board.reels.inner.offsetY * factor;
        sectionWidth = this.board.reels.size * factor;
        sectionHeight = sectionWidth * this.board.reels.windowsSize;

        const reels = this.getReels();
        for (let i = 0; i < reels.length; i++) {
            reels[i].resize(sectionWidth, sectionHeight);
            reels[i].move((repeatGap * i) + sectionOffsetX, sectionOffsetY);
        }

        this.shapes.payLine.resize((repeatGap * 4) + sectionWidth, sectionHeight);
        this.shapes.payLine.move(sectionOffsetX, sectionOffsetY);

        this.mask.clear();
        this.mask.alpha = 0.5;
        this.mask.beginFill(0x0);
        this.mask.drawRect(this.x + sectionOffsetX, this.y + sectionOffsetY, (repeatGap * 4) + sectionWidth, sectionHeight);
        this.mask.endFill();
    }

    public stop(result: SlotsSpinActionDto<TSymbols>): Promise<void> {
        this.setProps(
            {
                result: result.reels.map((r) => r.index),
                scores: result.reels.map((r) => r.attentions),
                anticipation_max_scatter: result.anticipationMaxSymbols,
                anticipation_min_scatter: result.anticipationMinSymbols,
                scatters: result.scatterSymbols,
                wilds: result.wildSymbols,
                state: "stopping",
            }
        );

        const reels = this.getReels();
        const minScattersToEnterAnticipation = result.anticipationMinSymbols ?? -1;
        const maxScattersToEnterAnticipation = result.anticipationMaxSymbols ?? -1;

        let totalDuration = this.board.reels.duration / 2;
        if (!this.isSkipping && !this.configuration.isFastModeActive) {
            totalDuration += this.board.reels.spinGranularDelay - AnticipationTransitionDelay;
        }

        const scatters = new Map<TSymbols, number>();
        let isAnticipating = false;
        let anticipationMultiplier = 0;
        for (let reelIndex = 0; reelIndex < reels.length; reelIndex++) {
            const reel = reels[reelIndex];

            // if there is a bonus board
            if (minScattersToEnterAnticipation >= 0 && maxScattersToEnterAnticipation >= minScattersToEnterAnticipation) {
                for (const scatter of this.getScatters(reel.reelSymbols, result.reels[reelIndex].index, result.scatterSymbols)) {
                    scatters.set(scatter[0], (scatters.get(scatter[0]) ?? 0) + scatter[1]);
                }
            }

            const anticipationIntensity = [ ...scatters.entries() ]
                .map(
                    (a) =>
                        (
                            // we have more than minimum number of scatters at this point
                            a[1] >= (minScattersToEnterAnticipation - 1) &&
                            // we have less than required number of scatters at this point
                            a[1] < maxScattersToEnterAnticipation &&
                            // there are still enough reels left that it is possible to gain the remaining scatters
                            (minScattersToEnterAnticipation - a[1]) <= (reels.length - (reelIndex + 1))
                        ) ? (a[1] - (minScattersToEnterAnticipation - 2)) : 0
                )
                .reduce((a, b) => Math.max(a, b), 0);

            if (this.isSkipping) {
                reel.skip();
            }

            void reel.stop(
                result.reels[reelIndex].index,
                result.scatterSymbols,
                result.wildSymbols,
                [ ...scatters.entries() ],
                anticipationMultiplier
            );

            if (reelIndex === reels.length - 1) {
                this.showAnticipate(0, 0, totalDuration);
                continue;
            }

            if (!!anticipationIntensity) {
                this.showAnticipate(reels.length - (reelIndex + 1), anticipationIntensity, totalDuration);
                anticipationMultiplier++;
                if (!this.isSkipping && !this.configuration.isFastModeActive) {
                    totalDuration += this.board.reels.anticipationDelay ?? 0;
                }
                isAnticipating = true;
            } else if (isAnticipating) {
                // anticipation ended
                this.showAnticipate(0, 0, totalDuration);
                isAnticipating = false;
            }

            if (!this.isSkipping && !this.configuration.isFastModeActive) {
                totalDuration += this.board.reels.resultGranularDelay;
            }
        }

        setTimeout(
            () => AudioHelper.stopAudio(this.reelSpinningSound),
            (totalDuration * 1000) - 100
        );

        return new Promise((resolve) => setTimeout(resolve, totalDuration * 1000));
    }

    public async finish(result?: SlotsSpinActionDto<TSymbols>): Promise<void> {
        this.setProps(
            {
                paylines: result?.payLines.map((p) => p.indexes) ?? [],
                state: "finished",
            }
        );

        await Promise.all(
            this.getReels().map(
                (r, i) => r.finish(
                    result?.payLines.filter((p) => i < p.matches).map((p): [TSymbols, number] => ([ p.symbol, p.indexes[i] ])) ?? [],
                    this.props.scores ? this.props.scores[i] : [],
                    this.props.picks ? this.props.picks[i] : []
                )
            )
        );

        if (result?.payLines.length) {
            await this.shapes.payLine.show(result.payLines.map((p) => p.indexes));
        }
    }

    public picked(result: SlotsPickActionDto): void {
        if (!this.props.result) {
            return;
        }

        const indexes: number[] = [];
        for (let reel = 0; reel < result.reels.length; reel++) {
            indexes.push(
                ...(
                    result.reels[reel].picks.map(
                        (p) => (reel * this.board.reels.windowsSize) + (p - this.props.result![reel])
                    )
                )
            );
        }

        void this.showPickems(indexes, false);
    }

    public async start(): Promise<void> {
        this.showAnticipate(0, 0);
        void this.showPickems([], true);
        this.setProps(
            {
                paylines: [],
                result: null,
                state: "starting",
            }
        );

        await this.shapes.payLine.hide();
        await Promise.all(
            this.getReels().map((r) => r.start())
        );
    }

    public win(): void {
        AudioHelper.playAudio(this.winSound);
    }

    public bigWin(): void {
        AudioHelper.playAudio(this.bigWinSound);
    }

    public reset(): void {
        this.setProps(
            {
                paylines: [],
                result: null,
                state: "idle",
            }
        );
    }

    public skip(): void {
        if (this.isSkipping) {
            return;
        }
        this.isSkipping = true;

        for (const reel of this.getReels()) {
            reel.skip();
        }
        this.shapes.payLine.skip();
    }

    protected stateChanged(props: GameBoardLayerProps): void {
        if (props.state === "idle") {
            void this.shapes.payLine.hide();

            for (const reel of this.getReels()) {
                reel.reset();
            }

            this.isSkipping = false;
            this.showAnticipate(0, 0);
            void this.showPickems([], true);
        }

        if (props.state === "starting") {
            this.isSkipping = false;
            setTimeout(
                () => AudioHelper.playAudio(this.reelSpinningSound),
                300
            );
        }
    }

    protected loaded(): void {
        super.loaded();

        for (const anticipation of this.getAnticipations()) {
            anticipation.container.alpha = 0;
        }

        for (const pickem of this.getPickems()) {
            pickem.container.alpha = 0;
        }

        for (const reel of this.getReels()) {
            reel.container.mask = this.mask;
        }

        this.resize(this.width, this.height);

        // if (this.logoAnimationTimer) {
        //     clearInterval(this.logoAnimationTimer);
        // }

        // this.logoAnimationTimer = setInterval(
        //     () => {
        //         this.shapes.logo.setProps(
        //             {
        //                 state: "static",
        //             }
        //         );

        //         this.shapes.logo.setProps(
        //             {
        //                 state: "playing",
        //             }
        //         );
        //     },
        //     2000
        // );
    }

    private pick(index: number): void {
        if (!this.props.result) {
            return;
        }

        const reel = Math.trunc(index / this.board.reels.windowsSize);
        const row = index % this.board.reels.windowsSize;
        this.getReels()[reel].activate(row + this.props.result[reel]);
        AudioHelper.playAudio(this.pickedSound);
        void this.showPickems([], true);
        this.configuration.pick(reel, row + this.props.result[reel]);
    }

    private async showPickems(indexes: number[], isTransit: boolean) {
        if (this.pickEmPromise) {
            await this.pickEmPromise;
        }

        void this.shapes.payLine.hide();

        const pickems = this.getPickems();
        let didShowNewSlots = false;
        let didHideOldSlots = false;

        const promises: Array<Promise<void>> = [];
        for (let i = 0; i < pickems.length; i++) {
            const pickem = pickems[i];
            if (indexes.includes(i)) {
                if (pickem.container.visible) {
                    continue;
                }

                didShowNewSlots = true;
                promises.push(
                    new Promise<void>(
                        (resolve) => {
                            gsap.fromTo(
                                pickem.container,
                                {
                                    alpha: 0,
                                },
                                {
                                    alpha: 1,
                                    duration: PickemTransitionDelay,
                                    ease: "power1.in",
                                    onStart: () => {
                                        pickem.container.visible = true;
                                        pickem.container.renderable = true;
                                        pickem.setProps({ state: "playing", startIndex: null });
                                    },
                                    onComplete: () => {
                                        pickem.container.alpha = 1;
                                        pickem.container.interactive = true;
                                        pickem.container.once("pointertap", () => this.pick(i));
                                        resolve();
                                    },
                                }
                            );
                        }
                    )
                );
            } else {
                if (!pickem.container.visible) {
                    continue;
                }

                didHideOldSlots = true;
                promises.push(
                    new Promise<void>(
                        (resolve) => {
                            gsap.fromTo(
                                pickem.container,
                                {
                                    alpha: 1,
                                },
                                {
                                    alpha: 0,
                                    duration: PickemTransitionDelay,
                                    ease: "power1.out",
                                    onStart: () => {
                                        pickem.container.interactive = false;
                                        pickem.container.removeAllListeners("pointertap");
                                    },
                                    onComplete: () => {
                                        pickem.container.alpha = 0;
                                        pickem.container.visible = false;
                                        pickem.container.renderable = false;
                                        pickem.setProps({ state: "static" });
                                        resolve();
                                    },
                                }
                            );
                        }
                    )
                );
            }
        }

        if (!isTransit) {
            if (didShowNewSlots && indexes.length > 0) {
                AudioHelper.playAudio(this.pickStartSound);
                AudioHelper.playAudio(this.pickBackgroundSound);
            }

            if (didHideOldSlots && indexes.length <= 0) {
                if (!didShowNewSlots) {
                    AudioHelper.playAudio(this.pickEndSound);
                }

                AudioHelper.stopAudio(this.pickBackgroundSound);
            }
        }

        this.pickEmPromise = Promise.all(promises).then();
        await this.pickEmPromise;
    }

    private showAnticipate(count: number, intensity: number, delay = 0) {
        if (this.isSkipping || this.configuration.isFastModeActive) {
            return;
        }

        const callback = () => {
            const anticipations = this.getAnticipations().reverse();
            let didShowNewAnticipations = false;
            let didHideOldAnticipation = false;

            for (let i = 0; i < anticipations.length; i++) {
                const anticipate = anticipations[i];

                if ((i + 1) === count) {
                    if (anticipate.container.visible) {
                        continue;
                    }

                    didShowNewAnticipations = true;
                    anticipate.container.visible = true;
                    anticipate.container.renderable = true;
                    anticipate.setProps({ state: "playing", startIndex: null });
                    gsap.to(
                        anticipate.container,
                        {
                            alpha: 1,
                            duration: AnticipationTransitionDelay,
                            delay: (count - i) * 0.05,
                            ease: "power1.in",
                            onComplete: () => {
                                anticipate.container.alpha = 1;
                            },
                        }
                    );
                } else {
                    if (!anticipate.container.visible) {
                        continue;
                    }

                    didHideOldAnticipation = true;
                    gsap.to(
                        anticipate.container,
                        {
                            alpha: 0,
                            duration: AnticipationTransitionDelay,
                            ease: "power1.out",
                            onComplete: () => {
                                anticipate.container.alpha = 0;
                                anticipate.container.visible = false;
                                anticipate.container.renderable = false;
                                anticipate.setProps({ state: "static" });
                            },
                        }
                    );
                }
            }

            const isAudioContinues = this.reelAnticipationSound.some((a) => a.sounds.every((s) => s.isLoop));
            if (isAudioContinues) {
                if (didShowNewAnticipations && count > 0) {
                    AudioHelper.playAudio(this.reelAnticipationSound);
                }

                if (didHideOldAnticipation && count <= 0) {
                    AudioHelper.stopAudio(this.reelAnticipationSound);
                }
            } else if (count > 0 && intensity > 0) {
                AudioHelper.playAudio(this.reelAnticipationSound, intensity - 1);
            }
        };

        if (!delay) {
            callback();
        } else {
            setTimeout(callback, delay * 1000);
        }
    }

    private getScatters(symbols: TSymbols[], index: number, scatters: TSymbols[]): Array<[TSymbols, number]> {
        const results: Array<[TSymbols, number]> = [];
        for (const scatter of scatters) {
            let value = 0;
            for (let i = 0; i < this.board.reels.windowsSize; i++) {
                const symbolIndex = (index + i) % symbols.length;
                if (scatter === symbols[symbolIndex]) {
                    value++;
                }
            }
            results.push([ scatter, value ]);
        }

        return results;
    }

    private getReels(): Array<GameReelLayer<TSymbols, TReels>> {
        return this.reels.map((r) => this.shapes[r]);
    }

    private getAnticipations(): GameImageLayer[] {
        return this.reels.map((r: GameReel<TReels>) => this.shapes[`${r}Anticipation`]);
    }

    private getPickems(): GameImageLayer[] {
        return ([ ...new Array<unknown>(this.board.reels.windowsSize * Object.keys(this.board.reels.symbols).length) ])
            .map(
                (_, i) => this.shapes[`${i}Pickem` as unknown as GamePickemIndex<TPickSlots>]!
            ).filter(
                (s) => !!s
            );
    }
}
