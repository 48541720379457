import "./index.style.scss";
import "modern-normalize/modern-normalize.css";
import { FinanceService, SessionService } from "@tgg/shared/src";
import { SlotsService } from "./services/slots/slots.service";
import App from "./app";
import React from "react";
import ReactDOM from "react-dom";

// TODO: Move to config
const baseUri = "https://dev-api.nocturnestudios.games";
// const baseUri = "http://localhost:15000";
const slotsService = new SlotsService(`${baseUri}/api/Slots`);
const sessionService = new SessionService(`${baseUri}/api`);
const financeService = new FinanceService(`${baseUri}/hubs/finance`);

ReactDOM.render(
    <React.StrictMode>
        <App

            sessionService={sessionService}
            slotsService={slotsService}
            financeService={financeService}
        />
    </React.StrictMode>,
    document.getElementById("root")
);
