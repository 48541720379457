import * as React from "react";
import { AccountingHelper } from "@tgg/shared/src/common/helpers/accounting.helper";
import { AudioHelper } from "../../common/helpers/audio.helper";
import { Button } from "../button/button.component";
import { FooterProperties } from "./footer.properties";
import { Home, Info, Maximize, Minimize, Volume2, VolumeX } from "react-feather";
import { Translate } from "../translate/translate";
import classNames from "classnames";
import screenfull from "screenfull";
import styles from "./footer.module.scss";

export class Footer extends React.PureComponent<FooterProperties, {
    isMuted: boolean;
}> {
    public constructor(props: FooterProperties) {
        super(props);
        this.state = {
            isMuted: false,
        };
    }

    public componentDidMount(): void {
        if (typeof document !== "undefined") {
            document.addEventListener("visibilitychange", this.onTabVisibilityChanged);
            if (this.canFullscreen() && screenfull.isEnabled) {
                screenfull.on("change", this.onFullscreenChanged);
            }
        }
    }

    public componentWillUnmount(): void {
        if (typeof document !== "undefined") {
            document.removeEventListener("visibilitychange", this.onTabVisibilityChanged);
            if (this.canFullscreen() && screenfull.isEnabled) {
                screenfull.off("change", this.onFullscreenChanged);
            }
        }
    }

    public render(): React.ReactNode {
        return <footer
            className={
                classNames(
                    styles.footer,
                    {
                        [styles.mobile]: this.props.isMobile,
                    }
                )
            }
        >
            {
                !this.props.isMobile && <section>
                    <span>
                        <Translate>NOCTURNE STUDIOS</Translate>
                    </span>
                </section>
            }
            <section>
                {
                    !this.props.isMobile && <div>
                        <h4><Translate>BET</Translate></h4>
                        <span>{`${this.props.currency ?? "$"}${AccountingHelper.formatMoney(this.props.totalBetAmount?.toFixed(2)) ?? "-"}`}</span>
                    </div>
                }
                <div>
                    <h4><Translate>BALANCE</Translate></h4>
                    <span>{`${this.props.currency ?? "$"}${AccountingHelper.formatMoney(this.props.balance?.toFixed(2)) ?? "-"}`}</span>
                </div>
                <div>
                    <h4><Translate>WIN</Translate></h4>
                    <span>{`${this.props.currency ?? "$"}${AccountingHelper.formatMoney(this.props.winAmount?.toFixed(2)) ?? "-"}`}</span>
                </div>
                {
                    !this.props.isMobile && !!this.props.autoRounds && <div>
                        <h4><Translate>AUTO</Translate></h4>
                        <span>{this.props.autoRounds?.toFixed(0)}</span>
                    </div>
                }
            </section>
            {
                !!this.props.isMobile && <Button
                    isDisabled={this.props.isBusy || !this.props.onHomeClicked}
                    onClick={this.props.onHomeClicked}
                    style="normal"
                    className={styles.home}
                ><Home /></Button>
            }
            {
                !!this.props.isMobile && <Button
                    isDisabled={!this.canFullscreen()}
                    onClick={this.onToggleFullscreen}
                    style="normal"
                    className={styles.fullscreen}
                >
                    {this.isFullscreen() ? <Minimize /> : <Maximize />}
                </Button>
            }
            <section>
                <Button
                    onClick={this.onToggleMute}
                    style="icon"
                >
                    {this.state.isMuted ? <VolumeX /> : <Volume2 />}
                </Button>
                <Button
                    isDisabled={this.props.isBusy || !this.props.onInfoClicked}
                    onClick={this.props.onInfoClicked}
                    style="icon"
                ><Info /></Button>
                {
                    !this.props.isMobile && <Button
                        isDisabled={this.props.isBusy || !this.props.onHomeClicked}
                        onClick={this.props.onHomeClicked}
                        style="icon"
                    ><Home /></Button>
                }
            </section>
        </footer>;
    }

    private canFullscreen() {
        return typeof window !== "undefined" && !!screenfull.isEnabled;
    }

    private isFullscreen() {
        if (!this.canFullscreen() || !screenfull.isEnabled) {
            return;
        }

        return screenfull.isFullscreen;
    }

    private onToggleFullscreen = () => {
        if (!this.canFullscreen() || !screenfull.isEnabled) {
            return;
        }

        void screenfull.toggle(window.document.body);
    };

    private onFullscreenChanged = () => {
        this.forceUpdate();
    };

    private onToggleMute = () => {
        if (this.state.isMuted) {
            AudioHelper.unmute();
            this.setState(
                {
                    isMuted: false,
                }
            );
        } else {
            AudioHelper.mute();
            this.setState(
                {
                    isMuted: true,
                }
            );
        }
    };

    private onTabVisibilityChanged = () => {
        if (!document || typeof document.hidden !== "boolean" || !!this.state.isMuted) {
            return;
        }

        if (!!document.hidden) {
            AudioHelper.mute();
        } else {
            AudioHelper.unmute();
        }
    };
}
