/* eslint-disable @typescript-eslint/naming-convention */
import * as PIXI from "pixi.js";
import {
    GameBoardDefinition,
    GameDefinition,
    GameFormDefinition,
    GameFormElementBaseDefinition,
    GameFormElementDefinition,
    GameFormElementType,
    GameImageDefinition,
    GameOverlayDefinition,
    GamePaylineDefinition,
    GameReelsDefinition,
    GameSound,
    GameSymbolDefinition,
    GameTransitionDefinition
} from "../game/game.properties";
import { GuideSlideDefinition } from "../guide-modal/guide-modal.properties";
import { HomeSlideDefinition } from "../home/home.properties";
import { PixiJSLayoutProperties } from "../../common/utilities/pixijs-rendering-layer";
import languages from "./defuse-game.languages.json";

export type DefuseGameDefinition = GameDefinition<DefuseGameSymbol, DefuseGameBoard, DefuseGameOverlay, GameSound, DefuseGameNumberOfReels>;

export interface DefuseGameConfiguration<T extends GameDefinition> {
    homePage?: {
        background: string;
        desktopSlides: HomeSlideDefinition[];
        mobileSlides: HomeSlideDefinition[];
    };
    guide: {
        desktopSlides: GuideSlideDefinition[];
        mobileSlides: GuideSlideDefinition[];
    };
    desktopGame: T;
    mobileGame: T;
    paylines: number;
}

const translate = (text: string, language: string) => {
    const langs = languages as Record<string, Record<string, string | undefined> | undefined>;
    if (!!language && typeof langs[language] === "object") {
        text = langs[language]![text] || text;
    }

    return text;
};

// --------------- game config
// the name of modals
export type DefuseGameOverlay = "win" | "big-win" | "pickem" | "free-spin-enter" | "free-spin-stats" | "free-spin-exit";

// the name of game boards
export type DefuseGameBoard = "main" | "bonus";

// the name of reel symbols
export type DefuseGameSymbol = "B" | "F" | "H1" | "H2" | "H3" | "H4" | "N1" | "N2" | "N3" | "N4" | "N5" | "W";

// the number of reels of each board
export type DefuseGameNumberOfReels = 5;

const SymbolDesktopDefinitions: Record<DefuseGameSymbol, GameSymbolDefinition> = {
    B: {
        url: "/assets/symbols/desktop/defuse-kit.json",
        heightFactor: 1,
        sound: {
            url: [
                "/assets/sounds/df_bonus_land_v1",
                "/assets/sounds/df_bonus_land_v1",
                "/assets/sounds/df_bonus_land_v1",
                "/assets/sounds/df_bonus_land_v2",
                "/assets/sounds/df_bonus_land_v3",
            ],
        },
    },
    F: {
        url: "/assets/symbols/desktop/c4.json",
        heightFactor: 1,
        sound: {
            url: [
                "/assets/sounds/df_scatter_land_v1",
                "/assets/sounds/df_scatter_land_v1",
                "/assets/sounds/df_scatter_land_v1",
                "/assets/sounds/df_scatter_land_v2",
                "/assets/sounds/df_scatter_land_v3",
            ],
        },
    },
    H1: {
        url: "/assets/symbols/desktop/t-0.json",
        heightFactor: 2,
    },
    H2: {
        url: "/assets/symbols/desktop/awp.json",
        heightFactor: 1,
    },
    H3: {
        url: "/assets/symbols/desktop/ak47.json",
        heightFactor: 1,
    },
    H4: {
        url: "/assets/symbols/desktop/m4.json",
        heightFactor: 1,
    },
    N1: {
        url: "/assets/symbols/desktop/desert-eagle.json",
        heightFactor: 1,
    },
    N2: {
        url: "/assets/symbols/desktop/knife.json",
        heightFactor: 1,
    },
    N3: {
        url: "/assets/symbols/desktop/hand-grenade.json",
        heightFactor: 1,
    },
    N4: {
        url: "/assets/symbols/desktop/flashbang.json",
        heightFactor: 1,
    },
    N5: {
        url: "/assets/symbols/desktop/a-bombsite.json",
        heightFactor: 1,
    },
    W: {
        url: "/assets/symbols/desktop/ct-0.json",
        heightFactor: 2,
    },
};

const SymbolMobileDefinitions: Record<DefuseGameSymbol, GameSymbolDefinition> = {
    B: {
        url: "/assets/symbols/mobile/defuse-kit.json",
        heightFactor: 1,
        sound: {
            url: [
                "/assets/sounds/df_bonus_land_v1",
                "/assets/sounds/df_bonus_land_v1",
                "/assets/sounds/df_bonus_land_v1",
                "/assets/sounds/df_bonus_land_v2",
                "/assets/sounds/df_bonus_land_v3",
            ],
        },
    },
    F: {
        url: "/assets/symbols/mobile/c4.json",
        heightFactor: 1,
        sound: {
            url: [
                "/assets/sounds/df_scatter_land_v1",
                "/assets/sounds/df_scatter_land_v1",
                "/assets/sounds/df_scatter_land_v1",
                "/assets/sounds/df_scatter_land_v2",
                "/assets/sounds/df_scatter_land_v3",
            ],
        },
    },
    H1: {
        url: "/assets/symbols/mobile/t-0.json",
        heightFactor: 2,
    },
    H2: {
        url: "/assets/symbols/mobile/awp.json",
        heightFactor: 1,
    },
    H3: {
        url: "/assets/symbols/mobile/ak47.json",
        heightFactor: 1,
    },
    H4: {
        url: "/assets/symbols/mobile/m4.json",
        heightFactor: 1,
    },
    N1: {
        url: "/assets/symbols/mobile/desert-eagle.json",
        heightFactor: 1,
    },
    N2: {
        url: "/assets/symbols/mobile/knife.json",
        heightFactor: 1,
    },
    N3: {
        url: "/assets/symbols/mobile/hand-grenade.json",
        heightFactor: 1,
    },
    N4: {
        url: "/assets/symbols/mobile/flashbang.json",
        heightFactor: 1,
    },
    N5: {
        url: "/assets/symbols/mobile/a-bombsite.json",
        heightFactor: 1,
    },
    W: {
        url: "/assets/symbols/mobile/ct-0.json",
        heightFactor: 2,
    },
};

export const DefuseGameMainReelSymbols: GameReelsDefinition<DefuseGameSymbol, DefuseGameNumberOfReels>["symbols"] = {
    reel0: [
        "N5", "N4", "N3", "H4", "N4", "H3", "H4", "N5", "N2", "N4", "H3", "N1", "N5", "N4", "H4", "N3", "H3", "N1", "N2", "B", "N1", "H1", "H1", "N2", "N5", "N2", "N4",
        "H1", "H1", "N3", "N2", "H2", "N1", "N5", "N3", "N4", "N5", "H3", "N3", "N4", "F", "N3", "H1", "H1", "N1", "N2", "H4", "N5", "N4", "H3", "N3", "H4", "H3", "N2",
        "H4", "N1", "H3", "H4", "N3", "N5", "B", "N1", "H1", "H1", "N2", "H3", "H4",
    ],
    reel1: [
        "N3", "H1", "H1", "N4", "N3", "H4", "N5", "N3", "N2", "W", "W", "N1", "N5", "N4", "H3", "H2", "N3", "N1", "H2", "N1", "B", "N2", "W", "W", "N5", "H2", "N4", "N3",
        "H2", "H4", "N5", "H1", "H1", "N5", "N4", "H2", "H4", "N4", "H3", "N5", "F", "N2", "H1", "H1", "N1", "N2", "H3", "N5", "N4", "H2", "N2", "N1", "N3", "H3", "N2",
        "H2", "H3", "N1", "N3", "N4", "B", "N1", "W", "W", "N2", "H2", "H3", "N4",
    ],
    reel2: [
        "H2", "N1", "N4", "N2", "H3", "H2", "H4", "N5", "N3", "H4", "H2", "N2", "H2", "H4", "N1", "B", "N4", "W", "W", "N5", "N4", "N2", "N5", "N1", "N4", "H2", "N1", "N4",
        "H2", "N2", "F", "N5", "H1", "H1", "N5", "N3", "H3", "N5", "N3", "H4", "H2", "N1", "H4", "N4", "N3", "B", "N1", "W", "W", "N5", "N2", "H4", "N3", "H2", "N1", "N4",
        "H2", "H4", "N2", "F", "N3", "H1", "H1", "N2", "N5", "H2", "H4", "N3",
    ],
    reel3: [
        "N1", "H3", "H4", "H2", "N3", "N5", "H2", "H3", "H4", "N1", "F", "N5", "H1", "H1", "N3", "N4", "H2", "H3", "N2", "B", "N4", "W", "W", "N3", "N1", "N4", "H2", "H4",
        "N3", "F", "N2", "H1", "H1", "N1", "N2", "N5", "H3", "H2", "N4", "B", "N5", "W", "W", "N4", "N1", "H2", "N4", "N5", "N3", "F", "N3", "H1", "H1", "N3", "N2", "N5",
        "H4", "H2", "N3", "N2", "N4", "H3", "N5", "N2", "H4", "N4", "N1", "H3", "H4",
    ],
    reel4: [
        "H2", "H3", "N3", "N4", "H4", "H2", "H3", "N5", "H2", "N4", "N5", "F", "N1", "H1", "H1", "N4", "H3", "H4", "N5", "N3", "B", "N2", "W", "W", "N2", "N1", "H4", "H3",
        "N4", "N1", "F", "N5", "H1", "H1", "N3", "H4", "H2", "N5", "N4", "N3", "B", "N4", "W", "W", "N1", "H3", "H4", "H2", "H3", "N1", "N2", "N3", "F", "N2", "H1", "H1",
        "H4", "N5", "N1", "N4", "B", "N5", "W", "W", "N3", "N2", "H4", "H2", "N2", "N3",
    ],
};

export const DefuseGameBonusReelSymbols: GameReelsDefinition<DefuseGameSymbol, DefuseGameNumberOfReels>["symbols"] = {
    reel0: [
        "N4", "H4", "H3", "N3", "H1", "H1", "N2", "H3", "H2", "N5", "N4", "H4", "H2", "N2", "N1", "H1", "H1", "N1", "N2", "H2", "H4", "N3", "N5", "H1", "H1", "N2", "H3",
        "H4", "H2", "N1", "H3", "H2", "H4", "N4", "H1", "H1", "N3", "N4", "H3", "H4", "N3", "N5", "H1", "H1", "N1", "N5", "H1", "H1",
    ],
    reel1: [
        "N5", "H1", "H1", "N4", "H3", "H4", "N1", "H1", "H1", "N2", "H4", "H2", "N3", "N4", "H3", "H2", "N2", "N1", "H1", "H1", "N1", "N3", "W", "W", "N3", "N5", "H1",
        "H1", "N2", "H4", "H3", "H2", "N1", "H4", "H2", "H3", "N4", "W", "W", "N5", "N2", "H1", "H1", "N3", "N5", "H1", "H1", "N4",
    ],
    reel2: [
        "H2", "H3", "N4", "H4", "H3", "N3", "N5", "H1", "H1", "N1", "H2", "N3", "N2", "W", "W", "N3", "N5", "W", "W", "N4", "H4", "N2", "H1", "H1", "N1", "H3", "N4",
        "N2", "W", "W", "N2", "N5", "H1", "H1", "N5", "H4", "H2", "H3", "N3", "H2", "H4", "N1", "H1", "H1", "N4", "N1", "W", "W",
    ],
    reel3: [
        "H3", "H4", "N1", "H2", "H3", "H4", "H2", "N2", "W", "W", "N2", "N5", "H1", "H1", "N3", "N5", "W", "W", "N2", "H4", "N4", "N3", "W", "W", "N4", "H2", "N1", "N3",
        "W", "W", "N2", "N1", "H1", "H1", "N5", "H3", "H2", "N4", "H1", "H1", "N5", "N4", "W", "W", "N1", "H3", "H4", "N3",
    ],
    reel4: [
        "W", "W", "N2", "N5", "W", "W", "N1", "H4", "N3", "N5", "N1", "B", "N4", "H1", "H1", "N3", "N4", "N2", "H2", "N5", "N4", "N2", "W", "W", "H3", "N1", "B", "N1",
        "N5", "W", "W", "H4", "N3", "W", "W", "N2", "H2", "N4", "N4", "N3", "W", "W", "H3", "H4", "W", "W", "H2", "H3",
    ],
};

const getDefuseGameDustTransition = (audio?: "fs" | "game"): GameTransitionDefinition => (
    {
        elements: {
            explosion: {
                type: GameFormElementType.Image,
                payload: {
                    url: "/assets/transitions/dust-0.json",
                    blend: PIXI.BLEND_MODES.SCREEN,
                    fit: "cover",
                    autoplay: true,
                    loop: false,
                },
            },
        },
        audio: !audio ? undefined : {
            url: `/assets/sounds/df_${audio}_intro_v1`,
        },
        duration: 2,
        outDelay: 0.5,
        inDelay: 0,
        canSkip: false,
        fullscreen: true,
        width: 1920,
        height: 1080,
        fit: "cover",
    }
);

const getGamePosition = (device: "desktop" | "mobile"): GameFormElementBaseDefinition & Required<Pick<GameFormElementBaseDefinition, "width" | "height">> => (
    {
        width: device === "desktop" ? 1600 : 1500,
        height: device === "desktop" ? 1200 : 1000,
        paddingX: 0,
        paddingY: 0,
        offsetX: 0,
        offsetY: 0,
        maxScale: 1,
        verticalAlign: "center",
        landscapeOverride: device === "desktop" ? {} : {
            paddingY: 140,
            offsetY: -80,
            verticalAlign: "center",
            maxScale: 0.45,
        },
        portraitOverride: device === "desktop" ? {} : {
            offsetY: -430,
            verticalAlign: "center",
            maxScale: 0.45,
        },
    }
);

const getOverlayElementPosition = (
    device: "desktop" | "mobile",
    width: number,
    height: number,
    autoSize = false,
    offsetX = 0,
    offsetY = 0
): PixiJSLayoutProperties => (
    {
        width: autoSize ? undefined : width,
        height: autoSize ? undefined : height,
        offsetX: (autoSize ? 0 : (getGamePosition(device).width - width) / 2) + offsetX,
        offsetY: (autoSize ? 0 : (getGamePosition(device).height - height) / 2) + offsetY + (device === "desktop" ? -40 : -35),
        paddingX: !autoSize ? 0 : (getGamePosition(device).width - width) / 2,
        paddingY: !autoSize ? 0 : (getGamePosition(device).height - height) / 2,
    }
);

const getOverlayLight = (device: "desktop" | "mobile", position: "primary" | "secondary", type: "danger" | "warning" | "win", delay = 0): GameFormElementDefinition<GameFormElementType.Image> => (
    {
        type: GameFormElementType.Image,
        appearDelay: delay,
        appearDuration: 0,
        payload: {
            url: `/assets/frame/${device}/${(device === "desktop") ? (position === "primary" ? "side" : "top") : (position === "primary" ? "center" : "bottom")}-${type}-0.json`,
            fit: "shrink",
            width: getGamePosition(device).width,
            height: getGamePosition(device).height,
            loop: false,
        },
    }
);

const getWinOverlay = (device: "desktop" | "mobile", language: string): GameOverlayDefinition => (
    {
        elements: {
            primaryLights: getOverlayLight(device, "primary", "win", 0.3),
            overlay: {
                type: GameFormElementType.Image,
                appearDelay: 0.3,
                payload: {
                    url: "/assets/overlays/overlay-frame.json",
                    fit: "shrink",
                    alpha: 0.8,
                    ...(getOverlayElementPosition(device, 1600, 1000)),
                },
            },
            bonus: {
                type: GameFormElementType.Text,
                appearDelay: 0.3,
                payload: {
                    source: "winnings",
                    preCaption: "{CURRENCY}",
                    textType: "money",
                    decimals: 2,
                    animated: false,
                    fontSize: 80,
                    dropShadow: true,
                    dropShadowBlur: 80,
                    dropShadowAlpha: 1,
                    dropShadowColor: 0x9d0302,
                    fill: 0xffffff,
                    padding: 100,
                    ...(getOverlayElementPosition(device, 1600, 1000, false, 0, -35)),
                },
            },
            text1: {
                type: GameFormElementType.Text,
                appearDelay: 0.3,
                payload: {
                    caption: translate("overlay.win", language),
                    fontSize: device === "desktop" ? "24px" : "36px",
                    ...(getOverlayElementPosition(device, 1600, 1000, false, 0, 35)),
                },
            },
        },
        duration: 1.3,
        fastDuration: 1.3,
        canSkip: false,
        blockExit: true,
        blockEnter: true,
        audio: {
            url: "/assets/sounds/df_win",
        },

        ...getGamePosition(device),
    }
);

const getBigWinOverlay = (device: "desktop" | "mobile", language: string): GameOverlayDefinition => (
    {
        elements: {
            primaryLights: getOverlayLight(device, "primary", "win"),
            secondaryLights: getOverlayLight(device, "secondary", "win", 1),
            overlay: {
                type: GameFormElementType.Image,
                appearDelay: 3,
                payload: {
                    url: "/assets/overlays/overlay-frame.json",
                    fit: "shrink",
                    alpha: 0.8,
                    ...(getOverlayElementPosition(device, 1600, 1000)),
                },
            },
            fire: {
                type: GameFormElementType.Image,
                appearDelay: 3.4,
                payload: {
                    url: "/assets/overlays/fire-frame-0.json",
                    blend: PIXI.BLEND_MODES.SCREEN,
                    fit: "shrink",
                    autoplay: true,
                    loop: true,
                    ...(getOverlayElementPosition(device, 1600, 1000)),
                },
            },
            explosion: {
                type: GameFormElementType.Image,
                appearDelay: 3,
                payload: {
                    url: "/assets/overlays/explosion-0.json",
                    blend: PIXI.BLEND_MODES.SCREEN,
                    fit: "contain",
                    autoplay: true,
                    loop: false,
                    ...(getOverlayElementPosition(device, 1330, 746)),
                },
            },
            image: {
                type: GameFormElementType.Image,
                appearDelay: 3.3,
                payload: {
                    url: `/assets/overlays/${language}/win-0.json`,
                    blend: PIXI.BLEND_MODES.SCREEN,
                    fit: "contain",
                    autoplay: true,
                    ...(getOverlayElementPosition(device, 800, 200, true, 0, -100)),
                },
            },
            bonus: {
                type: GameFormElementType.Text,
                appearDelay: 4,
                payload: {
                    source: "winnings",
                    preCaption: "{CURRENCY}",
                    textType: "money",
                    decimals: 2,
                    animated: true,
                    animationDuration: 5,
                    fontSize: 80,
                    dropShadow: true,
                    dropShadowBlur: 80,
                    dropShadowAlpha: 1,
                    dropShadowColor: 0x9d0302,
                    fill: 0xffffff,
                    padding: 100,
                    ...(getOverlayElementPosition(device, 1600, 1000, false, 0, 100)),
                },
            },
        },
        duration: 10,
        fastDuration: 10,
        canSkip: false,
        audio: {
            url: "/assets/sounds/df_big_win",
        },

        ...getGamePosition(device),
    }
);

const getPickEmOverlay = (device: "desktop" | "mobile", language: string): GameOverlayDefinition => (
    {
        elements: {
            overlay: {
                type: GameFormElementType.Image,
                appearDelay: 1,
                payload: {
                    url: "/assets/overlays/overlay-frame.json",
                    fit: "shrink",
                    alpha: 0.8,
                    ...(getOverlayElementPosition(device, 1600, 1000)),
                },
            },
            fire: {
                type: GameFormElementType.Image,
                appearDelay: 1,
                payload: {
                    url: "/assets/overlays/fire-frame-0.json",
                    blend: PIXI.BLEND_MODES.SCREEN,
                    fit: "shrink",
                    autoplay: true,
                    loop: true,
                    ...(getOverlayElementPosition(device, 1600, 1000)),
                },
            },
            image: {
                type: GameFormElementType.Image,
                appearDelay: 1,
                payload: {
                    url: `/assets/overlays/${language}/pick-bonus-0.json`,
                    blend: PIXI.BLEND_MODES.SCREEN,
                    fit: "shrink",
                    autoplay: true,
                    ...(getOverlayElementPosition(device, 800, 200, true, 0, -100)),
                },
            },
            ...(
                Object.fromEntries(
                    translate("overlay.pickem", language)
                        .split("\r\n")
                        .map(
                            (s, i) => (
                                [
                                    `text_${i}`,
                                    {
                                        type: GameFormElementType.Text,
                                        appearDelay: 1,
                                        payload: {
                                            caption: s,
                                            fill: 0xffffff,
                                            fontSize: device === "desktop" ? "24px" : "36px",
                                            ...(getOverlayElementPosition(device, 1600, 1000, false, 0, 100 + (i * 50))),
                                        },
                                    },
                                ]
                            )
                        )
                )
            ),
        },
        audio: {
            url: "/assets/sounds/df_bonustime_v1",
        },
        // duration: 3,
        fastDuration: 3,
        canSkip: true,

        ...getGamePosition(device),
    }
);

const getFreeSpinEnterOverlay = (device: "desktop" | "mobile", language: string): GameOverlayDefinition => (
    {
        elements: {
            overlay: {
                type: GameFormElementType.Image,
                payload: {
                    url: "/assets/overlays/overlay-frame.json",
                    fit: "shrink",
                    alpha: 0.8,
                    ...(getOverlayElementPosition(device, 1600, 1000)),
                },
            },
            fire: {
                type: GameFormElementType.Image,
                payload: {
                    url: "/assets/overlays/fire-frame-0.json",
                    blend: PIXI.BLEND_MODES.SCREEN,
                    fit: "shrink",
                    autoplay: true,
                    loop: true,
                    ...(getOverlayElementPosition(device, 1600, 1000)),
                },
            },
            image: {
                type: GameFormElementType.Image,
                payload: {
                    url: `/assets/overlays/${language}/free-spin-0.json`,
                    blend: PIXI.BLEND_MODES.SCREEN,
                    fit: "shrink",
                    autoplay: true,
                    ...(getOverlayElementPosition(device, 800, 200, true, 0, -100)),
                },
            },
            ...(
                Object.fromEntries(
                    translate("overlay.freespin", language)
                        .split("\r\n")
                        .map(
                            (s, i) => (
                                [
                                    `text_${i}`,
                                    {
                                        type: GameFormElementType.Text,
                                        payload: {
                                            caption: s,
                                            fill: 0xffffff,
                                            fontSize: device === "desktop" ? "24px" : "36px",
                                            ...(getOverlayElementPosition(device, 1600, 1000, false, 0, 100 + (50 * i))),
                                        },
                                    },
                                ]
                            )
                        )
                )
            ),
        },
        audio: {
            url: `/assets/sounds/${language}/df_thisisit_v1`,
        },
        delay: 1,
        fastDuration: 2,
        canSkip: true,
        blockExit: false,

        ...getGamePosition(device),
    }
);

const getFreeSpinExitOverlay = (device: "desktop" | "mobile"): GameOverlayDefinition => (
    {
        elements: {
            primaryLights: getOverlayLight(device, "primary", "danger"),
        },
        duration: 1,
        fastDuration: 1,
        canSkip: false,
        blockExit: true,
        blockEnter: true,

        ...getGamePosition(device),
    }
);

const getFreeSpinStatsOverlay = (device: "desktop" | "mobile", language: string): GameOverlayDefinition => (
    {
        elements: {
            primaryLights: getOverlayLight(device, "primary", "win"),
            overlay: {
                type: GameFormElementType.Image,
                appearDelay: 1,
                payload: {
                    url: "/assets/overlays/overlay-frame.json",
                    fit: "shrink",
                    alpha: 0.8,
                    ...(getOverlayElementPosition(device, 1600, 1000)),
                },
            },
            bonus: {
                type: GameFormElementType.Text,
                appearDelay: 1,
                payload: {
                    source: "winnings",
                    preCaption: "{CURRENCY}",
                    textType: "money",
                    decimals: 2,
                    animated: true,
                    animationDuration: 5,
                    fontSize: 160,
                    dropShadow: true,
                    dropShadowBlur: 80,
                    dropShadowAlpha: 1,
                    dropShadowColor: 0x9d0302,
                    fill: 0xffffff,
                    padding: 100,
                    ...(getOverlayElementPosition(device, 1600, 1000, false, 0, -30)),
                },
            },
            text1: {
                type: GameFormElementType.Text,
                appearDelay: 1,
                payload: {
                    caption: translate("overlay.freespin-stats", language),
                    fill: 0xffffff,
                    fontSize: device === "desktop" ? "24px" : "36px",
                    ...(getOverlayElementPosition(device, 1600, 1000, false, 0, 90)),
                },
            },
            text2: {
                type: GameFormElementType.Text,
                appearDelay: 1,
                payload: {
                    caption: translate("overlay.click", language),
                    fill: 0xffffff,
                    fontSize: device === "desktop" ? "24px" : "36px",
                    ...(getOverlayElementPosition(device, 1600, 1000, false, 0, 140)),
                },
            },
        },
        audio: {
            url: `/assets/sounds/${language}/df_welldone_v1`,
        },
        canSkip: true,
        fastDuration: 7,

        ...getGamePosition(device),
    }
);

export const getGame = (device: "desktop" | "mobile", language: string): Omit<DefuseGameDefinition, "boards" | "symbolScaling" | "symbols" | "symbolSize"> => (
    {
        sounds: getDefuseGameSoundDefinitions(language),
        overlays: {
            "win": getWinOverlay(device, language),
            "big-win": getBigWinOverlay(device, language),
            "pickem": getPickEmOverlay(device, language),
            // "pickem-free-spin": getPickEmFreeSpinOverlay(device, language),
            "free-spin-enter": getFreeSpinEnterOverlay(device, language),
            // "free-spin-delayed": getFreeSpinDelayedOverlay(device, language),
            "free-spin-stats": getFreeSpinStatsOverlay(device, language),
            "free-spin-exit": getFreeSpinExitOverlay(device),
        },
    }
);

const DefuseGameReelDefinition: Omit<GameReelsDefinition<DefuseGameSymbol, DefuseGameNumberOfReels>, "symbols" | "outer" | "size" | "gap"> = {
    windowsSize: 3,
    inner: {
        offsetX: 0,
        offsetY: 0,
    },
    anticipation: {
        sound: {
            url: [
                "/assets/sounds/df_anticipation_v1",
                "/assets/sounds/df_anticipation_v2",
                "/assets/sounds/df_anticipation_v3",
            ],
        },
        url: "/assets/reel/anticipation-alt-0.json",
        blend: PIXI.BLEND_MODES.SCREEN,
        width: 450,
        height: 900,
    },
    pickem: {
        url: "/assets/reel/pick-0.json",
        blend: PIXI.BLEND_MODES.SCREEN,
        width: 420,
        height: 420,
    },
    ease: "M0,0,C0.173,0,0.242,-0.024,0.322,0.07,0.401,0.163,0.449,0.367,0.502,0.506,0.546,0.622,0.62,0.864,0.726,0.956,0.799,1.02,0.869,1,1,1",
    direction: -1,
    duration: 1,
    speed: 2,
    spinGranularDelay: 0.1,
    resultFixedDelay: 0.1,
    resultGranularDelay: 0.5,
    anticipationDelay: 2,
    // pickEmDelay: 1,
};

const DefuseGameDesktopReelDefinition: Omit<GameReelsDefinition<DefuseGameSymbol, DefuseGameNumberOfReels>, "symbols"> = {
    ...DefuseGameReelDefinition,
    outer: {
        offsetX: 135,
        offsetY: 185,
    },
    size: 250,
    gap: 20,
};

const DefuseGameMobileReelDefinition: Omit<GameReelsDefinition<DefuseGameSymbol, DefuseGameNumberOfReels>, "symbols"> = {
    ...DefuseGameReelDefinition,
    outer: {
        offsetX: 80,
        offsetY: 90,
    },
    size: 250,
    gap: 20,
};

const DefuseGameMainBoardBackground: GameBoardDefinition<DefuseGameSymbol, DefuseGameNumberOfReels>["background"] = {
    image: {
        url: "/assets/backgrounds/background.mp4",
        fit: "cover",
        autoplay: false,
    },
    music: {
        url: "/assets/sounds/defuse_theme_v3",
        fadeIn: 0.3,
        fadeOut: 0.3,
        loop: true,
    },
};

const DefuseGameBonusBoardBackground: GameBoardDefinition<DefuseGameSymbol, DefuseGameNumberOfReels>["background"] = {
    image: {
        url: "/assets/backgrounds/freespin.mp4",
        fit: "cover",
        autoplay: false,
    },
    music: {
        url: "/assets/sounds/defuse_fs_theme_v1",
        fadeIn: 0.3,
        fadeOut: 0.3,
        loop: true,
    },
};

const getDefuseGameSoundDefinitions = (language: string): GameDefinition<DefuseGameSymbol, DefuseGameBoard, DefuseGameOverlay, GameSound, DefuseGameNumberOfReels>["sounds"] => (
    {
        // "wild": {
        //     url: "/assets/sounds/wild_landing",
        // },
        // "enter": {
        //     url: "/assets/sounds/df_game_intro_v1",
        // },
        "spin": [
            {
                url: "/assets/sounds/df_reelspin_v1",
                fadeIn: 0.2,
                loop: true,
            },
            {
                url: [
                    `/assets/sounds/${language}/df_bigone_v1`,
                    `/assets/sounds/${language}/df_crackin_v1`,
                    `/assets/sounds/${language}/df_keepitclean_v1`,
                    `/assets/sounds/${language}/df_niceone_v1`,
                    `/assets/sounds/${language}/df_notbad_v1`,
                    `/assets/sounds/${language}/df_onaroll_v1`,
                    `/assets/sounds/${language}/df_timetogetpaid_v1`,
                    `/assets/sounds/${language}/df_undercontrol_v1`,
                    "", // makes sure that we get some muted spins as well
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                ],
                delay: 0.3,
                loop: false,
                stoppable: false,
            },
        ],
        "land": {
            url: [
                "/assets/sounds/df_reelstop_v1",
                "/assets/sounds/df_reelstop_v2",
                "/assets/sounds/df_reelstop_v3",
                "/assets/sounds/df_reelstop_v4",
            ],
        },
        "picked": {
            url: "/assets/sounds/df_bonus_land_v1",
        },
        "pick-end": {
            url: `/assets/sounds/${language}/df_smashin_v1`,
        },
    }
);

const DefuseGamePaylineDefinition: GamePaylineDefinition = {
    sound: {
        url: "/assets/sounds/df_payline",
    },

    fireball: {
        url: "/assets/reel/fireball-0.json",
        blend: PIXI.BLEND_MODES.SCREEN,
        autoplay: true,
        width: 200,
        height: 200,
    },

    // - the original payline asset
    // url: "/assets/payline.json",
    // maxWidth: 200,
    // width: 266,
    // height: 240,
    // paddingX: 92,
    // paddingY: 0,

    // - the alternate payline asset
    url: "/assets/reel/payline-alt-0.json",
    blend: PIXI.BLEND_MODES.SCREEN,
    autoplay: true,

    // the actual size of usuble part of the asset
    width: 200,
    height: 160,

    // the max width of the asset to be scalled, compared to the `width` value
    maxWidth: 200,

    // the amount of pixels to be overlapped into the next asset
    paddingX: 60,
    paddingY: 0,

    delay: 0,
};

const getDefuseGameDesktopControls = (language: string): GameFormDefinition => (
    {
        elements: {
            "background": {
                type: GameFormElementType.Image,
                payload: {
                    url: `/assets/controls/${language}/control-background.json`,
                    fit: "contain",
                    ...(getOverlayElementPosition("desktop", 1600, 1000)),
                } as GameImageDefinition,
            },
            "fast-mode": {
                type: GameFormElementType.Button,
                payload: {
                    url: `/assets/controls/${language}/control-fast-mode.json`,
                    action: "toggle-fast-mode",
                    width: 120,
                    height: 80,
                    hitArea: [ -60, -40, 120, 80 ],
                    offsetX: -1092,
                    offsetY: -158,
                    fit: "shrink",
                    sound: {
                        url: "/assets/sounds/df_button",
                    },
                },
            },
            "auto-play": {
                type: GameFormElementType.Button,
                payload: {
                    url: `/assets/controls/${language}/control-auto-play.json`,
                    action: "toggle-auto-play",
                    width: 120,
                    height: 80,
                    hitArea: [ -60, -40, 120, 80 ],
                    offsetX: -952,
                    offsetY: -158,
                    fit: "shrink",
                    sound: {
                        url: "/assets/sounds/df_button",
                    },
                },
            },
            "spin": {
                type: GameFormElementType.Button,
                payload: {
                    url: "/assets/controls/control-spin.json",
                    action: "spin",
                    width: 120,
                    height: 120,
                    hitArea: [ -60, -60, 120, 120 ],
                    offsetX: -804,
                    offsetY: -158,
                    fit: "shrink",
                    sound: {
                        url: "/assets/sounds/df_press_spin_v1",
                    },
                },
            },
            "bet-amount": {
                type: GameFormElementType.Text,
                payload: {
                    source: "bet-amount",
                    preCaption: "{CURRENCY}",
                    textType: "money",
                    decimals: 2,
                    fill: 0x000,
                    fontSize: 24,
                    alpha: 0.7,
                    align: "left",
                    width: 88,
                    height: 30,
                    offsetX: 94,
                    offsetY: 604 - 158,
                    sound: {
                        url: "/assets/sounds/df_coins",
                    },
                },
            },
            "increase-bet": {
                type: GameFormElementType.Button,
                payload: {
                    url: "/assets/controls/control-increase-bet.json",
                    action: "increase-bet",
                    fill: 0x000,
                    fontSize: 20,
                    width: 40,
                    height: 36,
                    hitArea: [ -20, -18, 40, 36 ],
                    offsetX: -557,
                    offsetY: -23 - 158, // (80 / 2) - (36 / 2)
                    fit: "shrink",
                    sound: {
                        url: "/assets/sounds/df_coins",
                    },
                },
            },
            "decrease-bet": {
                type: GameFormElementType.Button,
                payload: {
                    url: "/assets/controls/control-decrease-bet.json",
                    action: "decrease-bet",
                    fill: 0x000,
                    fontSize: 20,
                    width: 40,
                    height: 36,
                    hitArea: [ -20, -18, 40, 36 ],
                    offsetX: -557,
                    offsetY: 23 - 158, // (80 / 2) - (36 / 2)
                    fit: "shrink",
                    sound: {
                        url: "/assets/sounds/df_coins",
                    },
                },
            },
            "max-bet": {
                type: GameFormElementType.Button,
                payload: {
                    url: `/assets/controls/${language}/control-max-bet.json`,
                    action: "max-bet",
                    width: 72,
                    height: 80,
                    hitArea: [ -36, -40, 72, 80 ],
                    offsetX: -484, // 60 + 24 + 120 + 16 + 40 + 16 + (72 / 2)
                    offsetY: -158,
                    fit: "shrink",
                    sound: {
                        url: "/assets/sounds/df_coins_max",
                    },
                },
            },
        },
        ...getGamePosition("desktop"),
        offsetX: 800,
        offsetY: 540,
    }
);

// --------------- final constructed game config
export const getDefuseGame = (language: string): DefuseGameConfiguration<DefuseGameDefinition> => (
    {
        homePage: {
            background: "/assets/home/background.jpg",
            desktopSlides: [
                {
                    image: `/assets/home/${language}/desktop-0.png`,
                    title: translate("home.freespin-title", language),
                    description: translate("home.freespin-desc", language),
                },
                {
                    image: `/assets/home/${language}/desktop-1.png`,
                    title: translate("home.pickem-title", language),
                    description: translate("home.pickem-desc", language),
                },
            ],
            mobileSlides: [
                {
                    image: "/assets/home/mobile-0.png",
                    title: translate("home.freespin-title", language),
                    description: translate("home.freespin-desc", language),
                },
                {
                    image: "/assets/home/mobile-1.png",
                    title: translate("home.pickem-title", language),
                    description: translate("home.pickem-desc", language),
                },
            ],
        },
        guide: {
            desktopSlides: [
                {
                    image: `/assets/guide/${language}/desktop-0.png`,
                    title: translate("guide-desktop.page1", language),
                },
                {
                    image: `/assets/guide/${language}/desktop-1.png`,
                    title: translate("guide-desktop.page2", language),
                },
                {
                    image: `/assets/guide/${language}/desktop-2.png`,
                    title: translate("guide-desktop.page3", language),
                },
                {
                    image: `/assets/guide/${language}/desktop-3.png`,
                    title: translate("guide-desktop.page4", language),
                },
                {
                    image: `/assets/guide/${language}/desktop-4.png`,
                    title: translate("guide-desktop.page5", language),
                },
                {
                    image: `/assets/guide/${language}/desktop-5.png`,
                    title: translate("guide-desktop.page6", language),
                },
            ],
            mobileSlides: [
                {
                    image: `/assets/guide/${language}/mobile-0.png`,
                    title: translate("guide-mobile.page1", language),
                },
                {
                    image: `/assets/guide/${language}/mobile-1.png`,
                    title: translate("guide-mobile.page2", language),
                },
                {
                    image: `/assets/guide/${language}/mobile-2.png`,
                    title: translate("guide-mobile.page3", language),
                },
            ],
        },
        paylines: 15,
        desktopGame: {
            ...getGame("desktop", language),
            symbols: SymbolDesktopDefinitions,
            symbolSize: 250,
            symbolScaling: 0.9,
            boards: {
                main: {
                    background: DefuseGameMainBoardBackground,
                    frame: {
                        background: {
                            url: "/assets/frame/desktop/desktop-main-reels.json",
                        },
                        foreground: {
                            url: "/assets/frame/desktop/desktop-main-frame.json",
                        },
                        ...getGamePosition("desktop"),
                    },
                    reels: {
                        ...DefuseGameDesktopReelDefinition,
                        symbols: DefuseGameMainReelSymbols,
                    },
                    payline: DefuseGamePaylineDefinition,
                    in: getDefuseGameDustTransition("game"),
                },
                bonus: {
                    background: DefuseGameBonusBoardBackground,
                    frame: {
                        background: {
                            url: "/assets/frame/desktop/desktop-bonus-reels.json",
                        },
                        foreground: {
                            url: "/assets/frame/desktop/desktop-bonus-frame.json",
                        },
                        ...getGamePosition("desktop"),
                    },
                    reels: {
                        ...DefuseGameDesktopReelDefinition,
                        symbols: DefuseGameBonusReelSymbols,
                    },
                    payline: DefuseGamePaylineDefinition,
                    in: getDefuseGameDustTransition("fs"),
                    out: getDefuseGameDustTransition(),
                },
            },
            controls: getDefuseGameDesktopControls(language),
            logo: {
                url: "/assets/frame/logo-0.json",
                width: 480,
                height: 240,
                paddingX: 560,
                paddingY: 480,
                offsetX: 0,
                offsetY: -457,
                maxScale: 1,
                autoplay: true,
            },
        },
        mobileGame: {
            ...getGame("mobile", language),
            symbols: SymbolMobileDefinitions,
            symbolSize: 125,
            symbolScaling: 0.9,
            boards: {
                main: {
                    background: DefuseGameMainBoardBackground,
                    frame: {
                        background: {
                            url: "/assets/frame/mobile/mobile-main-reels.json",
                        },
                        foreground: {
                            url: "/assets/frame/mobile/mobile-main-frame.json",
                        },
                        ...getGamePosition("mobile"),
                    },
                    reels: {
                        ...DefuseGameMobileReelDefinition,
                        symbols: DefuseGameMainReelSymbols,
                    },
                    payline: DefuseGamePaylineDefinition,
                    in: getDefuseGameDustTransition("game"),
                },
                bonus: {
                    background: DefuseGameBonusBoardBackground,
                    frame: {
                        background: {
                            url: "/assets/frame/mobile/mobile-bonus-reels.json",
                        },
                        foreground: {
                            url: "/assets/frame/mobile/mobile-bonus-frame.json",
                        },
                        ...getGamePosition("mobile"),
                    },
                    reels: {
                        ...DefuseGameMobileReelDefinition,
                        symbols: DefuseGameBonusReelSymbols,
                    },
                    payline: DefuseGamePaylineDefinition,
                    in: getDefuseGameDustTransition("fs"),
                    out: getDefuseGameDustTransition(),
                },
            },
            logo: {
                url: "/assets/frame/logo-0.json",
                width: 480,
                height: 240,
                paddingX: 510,
                paddingY: 380,
                autoplay: true,
                maxScale: 0.45,
                landscapeOverride: {
                    paddingY: 520,
                    offsetX: 0,
                    offsetY: -535,
                    verticalAlign: "center",
                },
                portraitOverride: {
                    minScale: 0.3,
                    offsetX: 0,
                    offsetY: 40,
                    verticalAlign: "top",
                },
            },
        },
    }
);
