import * as PIXI from "pixi.js";
import { AccountingHelper } from "@tgg/shared/src";
import { GameBindingsMap, GameTextDefinition } from "../../game.properties";
import { GameConfiguration } from "../../game-configuration.interface";
import { GameTextLayerProps } from "./game-text-layer.props";
import { PixiJSRenderingLayer } from "../../../../common/utilities/pixijs-rendering-layer";
import { TextStyle } from "pixi.js";
import gsap from "gsap";

export class GameTextLayer extends PixiJSRenderingLayer<
GameConfiguration,
{
    caption: PIXI.Text;
},
"",
GameTextLayerProps,
PIXI.Container
> {
    public constructor(
        configuration: GameConfiguration,
        app: PIXI.Application,
        private readonly definition: GameTextDefinition,
        bindings: GameBindingsMap = {}
    ) {
        super(
            configuration,
            app,
            new PIXI.Container(),
            {
                state: "normal",
                bindings,
            },
            {
                caption: new PIXI.Text(
                    ((!!definition.source && typeof bindings[definition.source] !== "undefined") ? bindings[definition.source]?.toString() : undefined) ?? definition.caption ?? "",
                    {
                        fontFamily: "Montserrat",
                        fontWeight: "700",
                        fontSize: "24px",
                        fill: "white",
                        ...definition,
                        align: "left",
                    }
                ),
            }
        );

        this.shapes.caption.alpha = definition.alpha ?? 1;
    }

    public resize(width: number, height: number): void {
        super.resize(width, height);

        const textSize = PIXI.TextMetrics.measureText(
            this.shapes.caption.text,
            this.shapes.caption.style as TextStyle
        );

        const box = this.calculateLayout(
            width,
            height,
            {
                fit: "shrink",
                maxScale: 1,
                ...this.definition,
                width: this.definition.width || textSize.width,
                height: this.definition.height || textSize.height,
            }
        );

        this.shapes.caption.scale.x = box.scale;
        this.shapes.caption.scale.y = box.scale;
        this.shapes.caption.x = box.center[0] - ((textSize.width / 2) * box.scale);
        this.shapes.caption.y = box.center[1] - ((textSize.height / 2) * box.scale);

        switch (this.definition.align ?? "center") {
            case "center":
                this.shapes.caption.x = box.center[0] - ((textSize.width / 2) * box.scale);
                break;
            case "right":
                this.shapes.caption.x = box.right - (textSize.width * box.scale);
                break;
            case "left":
                this.shapes.caption.x = box.left;
                break;
        }

        this.shapes.caption.y = box.center[1] - ((textSize.height / 2) * box.scale);

        // this.shapes.caption.scale.x = factor;
        // this.shapes.caption.scale.y = factor;
        // this.shapes.caption.pivot.x = textWidth / 2;
        // // this.shapes.caption.pivot.y = textHeight / 2;
        // // this.shapes.caption.x = (width - textSize.width) / 2;
        // this.shapes.caption.y = (height - textHeight) / 2;
    }

    public setProps(props: Partial<GameTextLayerProps>): void {
        if (!!props.bindings && props.bindings !== this.props.bindings) {
            let caption = this.definition.caption ?? "";

            if (!!this.definition.source && typeof props.bindings[this.definition.source] !== "undefined") {
                const value = props.bindings[this.definition.source];
                if (typeof value === "string") {
                    caption = value;
                } else if (typeof value === "number") {
                    caption = value.toFixed(this.definition.decimals ?? 0);
                }
            }

            if (this.definition.animated) {
                const numericValue = parseFloat(caption);
                if (!isNaN(numericValue) && isFinite(numericValue)) {
                    const counter = { a: 0 };
                    gsap.fromTo(
                        counter,
                        {
                            a: 0,
                        },
                        {
                            a: numericValue,
                            duration: this.definition.animationDuration ?? 0.3,
                            ease: "power2.out",
                            onUpdate: () => {
                                caption = counter.a.toFixed(this.definition.decimals ?? 0);

                                if (this.definition.textType === "money") {
                                    caption = AccountingHelper.formatMoney(caption);
                                }

                                this.shapes.caption.text = this.resolveStringTokens(
                                    (this.definition.preCaption ?? "") + caption + (this.definition.postCaption ?? "")
                                );
                                this.shapes.caption.updateText(false);
                                this.resize(this.width, this.height);
                            },
                        }
                    );
                }
            } else {
                if (this.definition.textType === "money") {
                    caption = AccountingHelper.formatMoney(caption);
                }

                this.shapes.caption.text = this.resolveStringTokens(
                    (this.definition.preCaption ?? "") + caption + (this.definition.postCaption ?? "")
                );
                this.shapes.caption.updateText(false);
                this.resize(this.width, this.height);
            }
        }

        super.setProps(props);
    }

    protected resolveStringTokens(value: string): string {
        return value.replace("{CURRENCY}", this.configuration.currency);
    }

    protected loaded(): void {
        super.loaded();

        this.shapes.caption.updateText(false);
        this.resize(this.width, this.height);
    }
}
