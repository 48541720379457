import * as PIXI from "pixi.js";
import { GameConfiguration } from "../../game-configuration.interface";
import { GameDefinition } from "../../game.properties";
import { GameSymbolLayerProps } from "./game-symbol-layer.props";
import { PixiJSRenderingLayer } from "../../../../common/utilities/pixijs-rendering-layer";

type GameSymbolAssetIndex<TSymbols extends string = string> = `GameSymbol_${TSymbols}`;

export class GameSymbolLayer<TSymbols extends string = string> extends PixiJSRenderingLayer<
GameConfiguration,
{
    wrapper: PIXI.Container;
},
GameSymbolAssetIndex<TSymbols>,
GameSymbolLayerProps<TSymbols>,
PIXI.Container
> {
    private currentSymbol: TSymbols | undefined;
    private currentSprite: PIXI.AnimatedSprite | undefined;
    private sprites: Map<TSymbols, PIXI.AnimatedSprite> | undefined;

    public constructor(
        configuration: GameConfiguration,
        app: PIXI.Application,
        private readonly game: GameDefinition
    ) {
        super(
            configuration,
            app,
            new PIXI.Container(),
            {
                state: "normal",
            },
            {
                wrapper: new PIXI.Container(),
            },
            Object.fromEntries(
                [
                    ...(
                        Object
                            .keys(game.symbols)
                            .map(
                                (k) => ([ `GameSymbol_${k}`, game.symbols[k as TSymbols].url ])
                            )
                    ),
                ]
            )
        );
    }

    public resize(width: number, height: number): void {
        super.resize(width, height);
        this.updateSize();
    }

    public setProps(props: Partial<GameSymbolLayerProps<TSymbols>>): void {
        super.setProps(props);
        if (props.symbol !== this.currentSymbol) {
            this.currentSymbol = props.symbol;
            this.updateSymbol();
        }
    }

    public focus(): void {
        this.setProps(
            {
                state: "focus",
            }
        );
    }

    public activate(): void {
        this.setProps(
            {
                state: "active",
            }
        );
    }

    public deactivate(): void {
        this.setProps(
            {
                state: "normal",
            }
        );
    }

    protected stateChanged(): void {
        this.updateAnimation();
    }

    protected loaded(): void {
        super.loaded();

        if (!this.sprites) {
            this.sprites = new Map<TSymbols, PIXI.AnimatedSprite>();
        }

        for (const symbol in this.game.symbols) {
            if (!Object.prototype.hasOwnProperty.call(this.game.symbols, symbol)) {
                continue;
            }

            const asset = this.assets[`GameSymbol_${symbol}` as GameSymbolAssetIndex<TSymbols>];
            if (!asset.textures) {
                continue;
            }

            const sprite = new PIXI.AnimatedSprite(asset.textures);
            sprite.animationSpeed = 0.5;

            // sprite.filters = [ new PIXI.filters.FXAAFilter() ];
            this.sprites.set(symbol as TSymbols, sprite);
        }

        this.updateSymbol();
    }

    private updateAnimation() {
        if (this.currentSprite) {
            if (this.props.state === "active") {
                this.currentSprite.loop = true;
                if (!this.currentSprite.playing) {
                    this.currentSprite.gotoAndPlay(0);
                }
            } else if (this.props.state === "focus") {
                this.currentSprite.loop = false;
                if (!this.currentSprite.playing) {
                    this.currentSprite.gotoAndPlay(0);
                }
            } else if (this.props.state === "normal") {
                if (!!this.currentSprite.playing) {
                    this.currentSprite.loop = false;
                }
            }
        }
    }

    private updateSymbol() {
        if (!this.props.symbol) {
            return;
        }

        const definition = this.game.symbols[this.props.symbol];
        const asset = this.assets[`GameSymbol_${this.props.symbol}`];
        if (!asset || !definition || !asset.textures) {
            return;
        }

        const newSprite = this.sprites?.get(this.props.symbol);
        if (newSprite === this.currentSprite || !newSprite) {
            return;
        }

        if (this.currentSprite) {
            this.currentSprite.gotoAndStop(0);
            this.shapes.wrapper.removeChild(this.currentSprite);
        }

        this.currentSprite = newSprite;
        this.shapes.wrapper.addChild(this.currentSprite);

        this.updateSize();
        this.updateAnimation();
    }

    private updateSize() {
        if (!this.props.symbol || !this.currentSprite) {
            return;
        }

        const definition = this.game.symbols[this.props.symbol];
        const asset = this.assets[`GameSymbol_${this.props.symbol}`];
        if (!asset || !definition || !asset.textures) {
            return;
        }

        const width = this.width;
        const height = this.height;
        let imageWidth = this.game.symbolSize;
        let imageHeight = this.game.symbolSize;
        const factor = Math.min(width / imageWidth, height / (imageHeight * definition.heightFactor)) * this.game.symbolScaling;
        imageWidth = (imageWidth * factor);
        imageHeight = ((imageHeight * definition.heightFactor) * factor);
        const offsetX = ((width - imageWidth) / 2);
        const offsetY = (height - imageHeight) / 2;

        this.currentSprite.scale.x = factor;
        this.currentSprite.scale.y = factor;
        this.shapes.wrapper.x = offsetX;
        this.shapes.wrapper.y = offsetY;
    }
}
